/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import './styles.scss';

export default function Footer() {
    return (
        <div className='bc-footer'>
            <p className='bc-footer-support'>
                <span>Help and Support</span>
                {'  '}(800) 479-3028
            </p>
            <p className='bc-footer-poweredBy'>Powered by OneRX</p>
        </div>
    );
}
