import React from 'react';
import { OneRxHub } from 'trucheck-widget';

import { apiConfig, brandConfigOneRx } from '../../config';
import Footer from '../Footer';

import './styles.css';
import styles from './styles';
import './../../styles.scss';

export default function Home() {
    return (
        <div className='bc-wrapper'>
            <OneRxHub
                apiUrl={apiConfig.apiBaseUrl}
                authConfig={apiConfig.authConfig}
                style={styles}
                brandConfig={brandConfigOneRx}
            />
            <Footer />
        </div>
    );
}
