import { BRAND_SPECIFIC_MESSAGES, CORE_MESSAGES } from './errorMessaging';
import { INSURANCE_SCREEN_CONFIG } from './insuranceScreenConfig';

export const brandConfigOneRx = {
    colors: {
        primary: '#6FCEEB',
        fieldBorder: '#A4A7B5'
    },
    errorMessages: {
        brandSpecificMessages: BRAND_SPECIFIC_MESSAGES,
        coreMessages: CORE_MESSAGES
    },
    insuranceScreen: INSURANCE_SCREEN_CONFIG,
    gaTrackingId: window._env_.REACT_APP_GA_TRACKING_ID || 'UA-175445564-1',
    siteUrl: 'https://hub.onerx.com/'
};
