import React from 'react';

import Home from './components/Home';

import './styles/index.css';

function App() {
    return <Home />;
}

export default App;
