const apiConfig = {
    apiBaseUrl: window._env_.REACT_APP_API_ENDPOINT,
    authConfig: window._env_.REACT_APP_AUTH_CONFIG || {
        issuer: 'https://truverisexternal.okta.com/oauth2/aus1i4sbcrxqzmcNO0h8',
        clientId: '0oa1je9w823hcEOm90h8',
        redirectUri: 'http://localhost:3000' //for test purpose only
    }
};

export default apiConfig;
