export const CORE_MESSAGES = {
    '01': 'Incorrect RxBIN',
    '02': 'Missing or Invalid Version/Release Number',
    '03': 'Missing or Invalid Transaction Code',
    '04': 'Missing or Incorrect RxPCN',
    '05': 'Missing or Invalid Pharmacy Number',
    '06': 'Missing or Incorrect RxGRP',
    '07': 'Incorrect Member ID',
    '08': 'Missing or Incorrect Person Code',
    '09': 'Missing or Invalid Birth of Date',
    10: 'Missing or Invalid Patient Gender Code',
    11: 'Missing or Incorrect Policyholder',
    12: 'Missing or Invalid Patient Location',
    13: 'Missing or Invalid Other Coverage Code',
    14: 'Missing or Invalid Eligibility Clarification Code',
    15: 'Missing or Invalid Date of Service',
    16: 'Missing or Invalid Prescription/Service Reference Number',
    17: 'Missing or Invalid Fill Number',
    19: 'Missing or Invalid Days Supply',
    '1C': 'Missing or Invalid Smoker/Non-Smoker Code',
    '1E': 'Missing or Invalid Prescriber Location Code',
    '1K': 'Missing or Invalid Patient Country Code',
    '1R': 'Version/Release Value not Supported',
    '1S': 'Transaction Code/Type Value not Supported',
    '1T': 'PCN must contain Processor/Payer Assigned Value',
    '1U': 'Transaction Count does not match Number of Transactions',
    '1V': 'Multiple Transactions not Supported',
    '1W': 'Multi-Ingredient Compound must be a Single Transaction',
    '1X': 'Vendor not Certified for Processor/Payer',
    '1Y': 'Claim Segment Required for Adjudication',
    '1Z': 'Clinical Segment Required for Adjudication',
    '2A': 'Missing or Invalid Medigap ID',
    '2B': 'Missing or Invalid Medicaid Indicator',
    '2C': 'Missing or Invalid Pregnancy Indicator',
    '2D': 'Missing or Invalid Provider Accept Assignment Indicator',
    '2E': 'Missing or Invalid Primary Care Provider ID Qualifier',
    '2G': 'Missing or Invalid Compound Ingredient Modifier Code Count',
    '2H': 'Missing or Invalid Compound Ingredient Modifier Code',
    '2J': 'Missing or Invalid Prescriber First Name',
    '2K': 'Missing or Invalid Prescriber Street Address',
    '2M': 'Missing or Invalid Prescriber City Address',
    '2N': 'Missing or Invalid Prescriber State/Province Address',
    '2P': 'Missing or Invalid Prescriber Zip/Postal Zone',
    '2Q': 'Missing or Invalid Additional Documentation Type ID',
    '2R': 'Missing or Invalid Length of Need',
    '2S': 'Missing or Invalid Length of Need Qualifier',
    '2T': 'Missing or Invalid Prescriber/Supplier Date Signed',
    '2U': 'Missing or Invalid Request Status',
    '2V': 'Missing or Invalid Request Period Begin Date',
    '2W': 'Missing or Invalid Request Period Recert/Revised Date',
    '2X': 'Missing or Invalid Supporting Documentation',
    '2Z': 'Missing or Invalid Question Number/Letter Count',
    20: 'Missing or Invalid Compound Code',
    21: 'Missing or Invalid Product/Service ID',
    22: 'Missing or Invalid Dispense as Written (DAW)/Product Selection Code',
    23: 'Missing or Invalid Ingredient Cost Submitted',
    25: 'Missing or Invalid Prescriber ID',
    26: 'Missing or Invalid Unit of Measure',
    27: 'Product Identifier not FDA/NSDE Listed',
    28: 'Missing or Invalid Date Prescription Written',
    29: 'Missing or Invalid Number of Refills Authorized',
    201: 'Patient Segment is not used for this Transaction Code',
    202: 'Insurance Segment is not used for this Transaction Code',
    203: 'Claim Segment is not used for this Transaction Code',
    204: 'Pharmacy Provider Segment is not used for this Transaction Code',
    205: 'Prescriber Segment is not used for this Transaction Code',
    206: 'Coordination of Benefits/Other Payments Segment is not used for this Transaction Code',
    207: 'Workers\u2019 Compensation Segment is not used for this Transaction Code',
    208: 'DUR/PPS Segment is not used for this Transaction Code',
    209: 'Pricing Segment is not used for this Transaction Code',
    210: 'Coupon Segment is not used for this Transaction Code',
    211: 'Compound Segment is not used for this Transaction Code',
    212: 'Prior Authorization Segment is not used for this Transaction Code',
    213: 'Clinical Segment is not used for this Transaction Code',
    214: 'Additional Documentation Segment is not used for this Transaction Code',
    215: 'Facility Segment is not used for this Transaction Code',
    216: 'Narrative Segment is not used for this Transaction Code',
    217: 'Purchaser Segment is not used for this Transaction Code',
    218: 'Service Provider Segment is not used for this Transaction Code',
    219: 'Patient ID Qualifier is not used for this Transaction Code',
    220: 'Patient ID is not used for this Transaction Code',
    221: 'Date of Birth is not used for this Transaction Code',
    222: 'Patient Gender Code is not used for this Transaction Code',
    223: 'Patient First Name is not used for this Transaction Code',
    224: 'Patient Last Name is not used for this Transaction Code',
    225: 'Patient Street Address is not used for this Transaction Code',
    226: 'Patient City Address is not used for this Transaction Code',
    227: 'Patient State/Province Address is not used for this Transaction Code',
    228: 'Patient ZIP/Postal Zone is not used for this Transaction Code',
    229: 'Patient Phone Number is not used for this Transaction Code',
    230: 'Place of Service is not used for this Transaction Code',
    231: 'Employer ID is not used for this Transaction Code',
    232: 'Smoker/Non-Smoker Code is not used for this Transaction Code',
    233: 'Pregnancy Indicator is not used for this Transaction Code',
    234: 'Patient E-Mail Address is not used for this Transaction Code',
    235: 'Patient Residence is not used for this Transaction Code',
    236: 'Patient ID Associated State/Province Address is not used for this Transaction Code',
    237: 'Cardholder First Name is not used for this Transaction Code',
    238: 'Cardholder Last Name is not used for this Transaction Code',
    239: 'Home Plan is not used for this Transaction Code',
    240: 'Plan ID is not used for this Transaction Code',
    241: 'Eligibility Clarification Code is not used for this Transaction Code',
    242: 'Group ID is not used for this Transaction Code',
    243: 'Person Code is not used for this Transaction Code',
    244: 'Patient Relationship Code is not used for this Transaction Code',
    245: 'Other Payer BIN Number is not used for this Transaction Code',
    246: 'Other Payer Processor Control Number is not used for this Transaction Code',
    247: 'Other Payer Cardholder ID is not used for this Transaction Code',
    248: 'Other Payer Group ID is not used for this Transaction Code',
    249: 'Medigap ID is not used for this Transaction Code',
    250: 'Medicaid Indicator is not used for this Transaction Code',
    251: 'Provider Accept Assignment Indicator is not used for this Transaction Code',
    252: 'CMS Part D Defined Qualified Facility is not used for this Transaction Code',
    253: 'Medicaid ID Number is not used for this Transaction Code',
    254: 'Medicaid Agency Number is not used for this Transaction Code',
    255: 'Associated Prescription/Service Reference Number is not used for this Transaction Code',
    256: 'Associated Prescription/Service Date is not used for this Transaction Code',
    257: 'Procedure Modifier Code Count is not used for this Transaction Code',
    258: 'Procedure Modifier Code is not used for this Transaction Code',
    259: 'Quantity Dispensed is not used for this Transaction Code',
    260: 'Fill Number is not used for this Transaction Code',
    261: 'Days Supply is not used for this Transaction Code',
    262: 'Compound Code is not used for this Transaction Code',
    263: 'Dispense as Written(DAW)/Product Selection Code is not used for this Transaction Code',
    264: 'Date Prescription Written is not used for this Transaction Code',
    265: 'Number of Refills Authorized is not used for this Transaction Code',
    266: 'Prescription Origin Code is not used for this Transaction Code',
    267: 'Submission Clarification Code Count is not used for this Transaction Code',
    268: 'Submission Clarification Code is not used for this Transaction Code',
    269: 'Quantity Prescribed is not used for this Transaction Code',
    270: 'Other Coverage Code is not used for this Transaction Code',
    271: 'Special Packaging Indicator is not used for this Transaction Code',
    272: 'Originally Prescribed Product/Service ID Qualifier is not used for this Transaction Code',
    273: 'Originally Prescribed Product/Service Code is not used for this Transaction Code',
    274: 'Originally Prescribed Quantity is not used for this Transaction Code',
    275: 'Alternate ID is not used for this Transaction Code',
    276: 'Scheduled Prescription ID Number is not used for this Transaction Code',
    277: 'Unit of Measure is not used for this Transaction Code',
    278: 'Level of Service is not used for this Transaction Code',
    279: 'Prior Authorization Type Code is not used for this Transaction Code',
    280: 'Prior Authorization Number Submitted is not used for this Transaction Code',
    281: 'Intermediary Authorization Type ID is not used for this Transaction Code',
    282: 'Intermediary Authorization ID is not used for this Transaction Code',
    283: 'Dispensing Status is not used for this Transaction Code',
    284: 'Quantity Intended to be Dispensed is not used for this Transaction Code',
    285: 'Days Supply Intended to be Dispensed is not used for this Transaction Code',
    286: 'Delay Reason Code is not used for this Transaction Code',
    287: 'Transaction Reference Number is not used for this Transaction Code',
    288: 'Patient Assignment Indicator (Direct Member Reimbursement Indicator) is not used for this Transaction Code',
    289: 'Route of Administration is not used for this Transaction Code',
    290: 'Compound Type is not used for this Transaction Code',
    291: 'Medicaid Subrogation Internal Control Number/Transaction Control Number (ICN/TCN) is not used for this Transaction Code',
    292: 'Pharmacy Service Type is not used for this Transaction Code',
    293: 'Associated Prescription/Service Provider ID Qualifier is not used for this Transaction Code',
    294: 'Associated Prescription/Service Provider ID is not used for this Transaction Code',
    295: 'Associated Prescription/Service Reference Number Qualifier is not used for this Transaction Code',
    296: 'Associated Prescription/Service Reference Fill Number is not used for this Transaction Code',
    297: 'Time of Service is not used for this Transaction Code',
    298: 'Sales Transaction ID is not used for this Transaction Code',
    299: 'Reported Payment Type is not used for this Transaction Code',
    '3A': 'Missing or Invalid Request Type',
    '3B': 'Missing or Invalid Request Period Date-Begin',
    '3C': 'Missing or Invalid Request Period Date-End',
    '3D': 'Missing or Invalid Basis of Request',
    '3E': 'Missing or Invalid Authorized Representative First Name',
    '3F': 'Missing or Invalid Authorized Representative Last Name',
    '3G': 'Missing or Invalid Authorized Representative Street Address',
    '3H': 'Missing or Invalid Authorized Representative City Address',
    '3J': 'Missing or Invalid Authorized Representative State/Province Address',
    '3K': 'Missing or Invalid Authorized Representative Zip/Postal Zone',
    '3M': 'Missing or Invalid Prescriber Phone Number',
    '3N': 'Missing or Invalid Prior Authorized Number Assigned',
    '3P': 'Missing or Invalid Authorization Number',
    '3Q': 'Missing or Invalid Facility Name',
    '3R': 'Prior Authorization not Required',
    '3S': 'Missing or Invalid Prior Authorization Supporting Documentation',
    '3T': 'Active Prior Authorization Exists Resubmit at Expiration of Prior Authorization',
    '3U': 'Missing or Invalid Facility Street Address',
    '3V': 'Missing or Invalid Facility State/Province Address',
    '3W': 'Prior Authorization in Process',
    '3X': 'Authorization Number not Found',
    '3Y': 'Prior Authorization Denied',
    30: 'Reversal Request Outside Processor Reversal Window',
    31: 'No Matching Paid Claim Found for Reversal Request',
    32: 'Missing or Invalid Level of Service',
    33: 'Missing or Invalid Prescription Origin Code',
    34: 'Missing or Invalid Submission Clarification Code',
    35: 'Missing or Invalid Primary Care Provider ID',
    38: 'Missing or Invalid Basis of Cost Determination',
    39: 'Missing or Invalid Diagnosis Code',
    300: 'Provider ID Qualifier is not used for this Transaction Code',
    301: 'Provider ID is not used for this Transaction Code',
    302: 'Prescriber ID Qualifier is not used for this Transaction Code',
    303: 'Prescriber ID is not used for this Transaction Code',
    304: 'Prescriber ID Associated State/Province Address is not used for this Transaction Code',
    305: 'Prescriber Last Name is not used for this Transaction Code',
    306: 'Prescriber Phone Number is not used for this Transaction Code',
    307: 'Primary Care Provider ID Qualifier is not used for this Transaction Code',
    308: 'Primary Care Provider ID is not used for this Transaction Code',
    309: 'Primary Care Provider Last Name is not used for this Transaction Code',
    310: 'Prescriber First Name is not used for this Transaction Code',
    311: 'Prescriber Street Address is not used for this Transaction Code',
    312: 'Prescriber City Address is not used for this Transaction Code',
    313: 'Prescriber State/Province Address is not used for this Transaction Code',
    314: 'Prescriber ZIP/Postal Zone is not used for this Transaction Code',
    315: 'Prescriber Alternate ID Qualifier is not used for this Transaction Code',
    316: 'Prescriber Alternate ID is not used for this Transaction Code',
    317: 'Prescriber Alternate ID Associated State/Province Address is not used for this Transaction Code',
    318: 'Other Payer ID Qualifier is not used for this Transaction Code',
    319: 'Other Payer ID is not used for this Transaction Code',
    320: 'Other Payer Date is not used for this Transaction Code',
    321: 'Internal Control Number is not used for this Transaction Code',
    322: 'Other Payer Amount Paid Count is not used for this Transaction Code',
    323: 'Other Payer Amount Paid Qualifier is not used for this Transaction Code',
    324: 'Other Payer Amount Paid is not used for this Transaction Code',
    325: 'Other Payer Reject Count is not used for this Transaction Code',
    326: 'Other Payer Reject Code is not used for this Transaction Code',
    327: 'Other Payer-Patient Responsibility Amount Count is not used for this Transaction Code',
    328: 'Other Payer-Patient Responsibility Amount Qualifier is not used for this Transaction Code',
    329: 'Other Payer-Patient Responsibility Amount is not used for this Transaction Code',
    330: 'Benefit Stage Count is not used for this Transaction Code',
    331: 'Benefit Stage Qualifier is not used for this Transaction Code',
    332: 'Benefit Stage Amount is not used for this Transaction Code',
    333: 'Employer Name is not used for this Transaction Code',
    334: 'Employer Street Address is not used for this Transaction Code',
    335: 'Employer City Address is not used for this Transaction Code',
    336: 'Employer State/Province Address is not used for this Transaction Code',
    337: 'Employer Zip/Postal Code is not used for this Transaction Code',
    338: 'Employer Phone Number is not used for this Transaction Code',
    339: 'Employer Contact Name is not used for this Transaction Code',
    340: 'Carrier ID is not used for this Transaction Code',
    341: 'Claim/Reference ID is not used for this Transaction Code',
    342: 'Billing Entity Type Indicator is not used for this Transaction Code',
    343: 'Pay to Qualifier is not used for this Transaction Code',
    344: 'Pay to ID is not used for this Transaction Code',
    345: 'Pay to Name is not used for this Transaction Code',
    346: 'Pay to Street Address is not used for this Transaction Code',
    347: 'Pay to City Address is not used for this Transaction Code',
    348: 'Pay to State/Province Address is not used for this Transaction Code',
    349: 'Pay to ZIP/Postal Zone is not used for this Transaction Code',
    350: 'Generic Equivalent Product ID Qualifier is not used for this Transaction Code',
    351: 'Generic Equivalent Product ID is not used for this Transaction Code',
    352: 'DUR/PPS Code Counter is not used for this Transaction Code',
    353: 'Reason for Service Code is not used for this Transaction Code',
    354: 'Professional Service Code is not used for this Transaction Code',
    355: 'Result of Service Code is not used for this Transaction Code',
    356: 'DUR/PPS Level of Effort is not used for this Transaction Code',
    357: 'DUR Co-Agent ID Qualifier is not used for this Transaction Code',
    358: 'DUR Co-Agent ID is not used for this Transaction Code',
    359: 'Ingredient Cost Submitted is not used for this Transaction Code',
    360: 'Dispensing Fee Submitted is not used for this Transaction Code',
    361: 'Professional Service Fee Submitted is not used for this Transaction Code',
    362: 'Patient Paid Amount Submitted is not used for this Transaction Code',
    363: 'Incentive Amount Submitted is not used for this Transaction Code',
    364: 'Other Amount Claimed Submitted Count is not used for this Transaction Code',
    365: 'Other Amount Claimed Submitted Qualifier is not used for this Transaction Code',
    366: 'Other Amount Claimed Submitted is not used for this Transaction Code',
    367: 'Flat Sales Tax Amount Submitted is not used for this Transaction Code',
    368: 'Percentage Sales Tax Amount Submitted is not used for this Transaction Code',
    369: 'Percentage Sales Tax Rate Submitted is not used for this Transaction Code',
    370: 'Percentage Sales Tax Basis Submitted is not used for this Transaction Code',
    371: 'Usual and Customary Charge is not used for this Transaction Code',
    372: 'Gross Amount Due is not used for this Transaction Code',
    373: 'Basis of Cost Determination is not used for this Transaction Code',
    374: 'Medicaid Paid Amount is not used for this Transaction Code',
    375: 'Coupon Value Amount is not used for this Transaction Code',
    376: 'Compound Ingredient Drug Cost is not used for this Transaction Code',
    377: 'Compound Ingredient Basis of Cost Determination is not used for this Transaction Code',
    378: 'Compound Ingredient Modifier Code Count is not used for this Transaction Code',
    379: 'Compound Ingredient Modifier Code is not used for this Transaction Code',
    380: 'Authorized Representative First Name is not used for this Transaction Code',
    381: 'Authorized Rep. Last Name is not used for this Transaction Code',
    382: 'Authorized Rep. Street Address is not used for this Transaction Code',
    383: 'Authorized Rep. City is not used for this Transaction Code',
    384: 'Authorized Rep. State/Province is not used for this Transaction Code',
    385: 'Authorized Rep. Zip/Postal Code is not used for this Transaction Code',
    386: 'Prior Authorization Number - Assigned is not used for this Transaction Code',
    387: 'Authorization Number is not used for this Transaction Code',
    388: 'Prior Authorization Supporting Documentation is not used for this Transaction Code',
    389: 'Diagnosis Code Count is not used for this Transaction Code',
    390: 'Diagnosis Code Qualifier is not used for this Transaction Code',
    391: 'Diagnosis Code is not used for this Transaction Code',
    392: 'Clinical Information Counter is not used for this Transaction Code',
    393: 'Measurement Date is not used for this Transaction Code',
    394: 'Measurement Time is not used for this Transaction Code',
    395: 'Measurement Dimension is not used for this Transaction Code',
    396: 'Measurement Unit is not used for this Transaction Code',
    397: 'Measurement Value is not used for this Transaction Code',
    398: 'Request Period Begin Date is not used for this Transaction Code',
    399: 'Request Period Recert/Revised Date is not used for this Transaction Code',
    '4B': 'Missing or Invalid Question Number/Letter',
    '4C': 'Missing or Invalid Coordination of Benefits/Other Payments Count',
    '4D': 'Missing or Invalid Question Percent Response',
    '4E': 'Missing or Invalid Primary Care Provider Last Name',
    '4G': 'Missing or Invalid Question Date Response',
    '4H': 'Missing or Invalid Question Dollar Amount Response',
    '4J': 'Missing or Invalid Question Numeric Response',
    '4K': 'Missing or Invalid Question Alphanumeric Response',
    '4M': 'Compound Ingredient Modifier Code Count does not match Number of Repetitions',
    '4N': 'Question Number/Letter Count does not match Number of Repetitions',
    '4P': 'Question Number/Letter not Valid for Identified Document',
    '4Q': 'Question Response not Appropriate for Question Number/Letter',
    '4R': 'Required Question Number/Letter Response for Indicated Document Missing',
    '4S': 'Compound Product ID Requires a Modifier Code',
    '4T': 'Missing or Invalid Additional Documentation Segment',
    '4W': 'Must Fill through Specialty Pharmacy',
    '4X': 'Missing or Invalid Patient Residence',
    '4Y': 'Patient Residence Value not Supported',
    '4Z': 'Place of Service not Supported by Plan',
    40: 'Pharmacy not Contracted With Plan on Date of Service',
    41: 'Submit Bill to Other Processor or Primary Payer',
    42: "Plan's Prescriber Data Base indicates the Prescriber ID Submitted is Inactive or Expired",
    43: "Plan's Prescriber Data Base indicates the Associated DEA to Submitted Prescriber ID is Inactive",
    44: "Plan's Prescriber Data Base indicates the Associated DEA to Submitted Prescriber ID is not Found",
    46: "Plan's Prescriber Data Base indicates Associated DEA to Submitted Prescriber ID does not allow this Drug DEA Schedule",
    400: 'Request Status is not used for this Transaction Code',
    401: 'Length of Need Qualifier is not used for this Transaction Code',
    402: 'Length of Need is not used for this Transaction Code',
    403: 'Prescriber/Supplier Date Signed is not used for this Transaction Code',
    404: 'Supporting Documentation is not used for this Transaction Code',
    405: 'Question Number/Letter Count is not used for this Transaction Code',
    406: 'Question Number/Letter is not used for this Transaction Code',
    407: 'Question Percent Response is not used for this Transaction Code',
    408: 'Question Date Response is not used for this Transaction Code',
    409: 'Question Dollar Amount Response is not used for this Transaction Code',
    410: 'Question Numeric Response is not used for this Transaction Code',
    411: 'Question Alphanumeric Response is not used for this Transaction Code',
    412: 'Facility ID is not used for this Transaction Code',
    413: 'Facility Name is not used for this Transaction Code',
    414: 'Facility Street Address is not used for this Transaction Code',
    415: 'Facility City Address is not used for this Transaction Code',
    416: 'Facility State/Province Address is not used for this Transaction Code',
    417: 'Facility ZIP/Postal Zone is not used for this Transaction Code',
    418: 'Purchaser ID Qualifier is not used for this Transaction Code',
    419: 'Purchaser ID is not used for this Transaction Code',
    420: 'Purchaser ID Associated State Code is not used for this Transaction Code',
    421: 'Purchaser Date of Birth is not used for this Transaction Code',
    422: 'Purchaser Gender Code is not used for this Transaction Code',
    423: 'Purchaser First Name is not used for this Transaction Code',
    424: 'Purchaser Last Name is not used for this Transaction Code',
    425: 'Purchaser Street Address is not used for this Transaction Code',
    426: 'Purchaser City Address is not used for this Transaction Code',
    427: 'Purchaser State/Province Address is not used for this Transaction Code',
    428: 'Purchaser ZIP/Postal Zone is not used for this Transaction Code',
    429: 'Purchaser Country Code is not used for this Transaction Code',
    430: 'Purchaser Relationship Code is not used for this Transaction Code',
    431: 'Released Date is not used for this Transaction Code',
    432: 'Released Time is not used for this Transaction Code',
    433: 'Service Provider Name is not used for this Transaction Code',
    434: 'Service Provider Street Address is not used for this Transaction Code',
    435: 'Service Provider City Address is not used for this Transaction Code',
    436: 'Service Provider State/Province Address is not used for this Transaction Code',
    437: 'Service Provider ZIP/Postal Zone is not used for this Transaction Code',
    438: 'Seller ID Qualifier is not used for this Transaction Code',
    439: 'Seller ID is not used for this Transaction Code',
    440: 'Seller Initials is not used for this Transaction Code',
    441: 'Other Amount Claimed Submitted Grouping Incorrect',
    442: 'Other Payer Amount Paid Grouping Incorrect',
    443: 'Other Payer-Patient Responsibility Amount Grouping Incorrect',
    444: 'Benefit Stage Amount Grouping Incorrect',
    445: 'Diagnosis Code Grouping Incorrect',
    446: 'COB/Other Payments Segment incorrectly Formatted',
    447: 'Additional Documentation Segment incorrectly Formatted',
    448: 'Clinical Segment incorrectly Formatted',
    449: 'Patient Segment incorrectly Formatted',
    450: 'Insurance Segment incorrectly Formatted',
    451: 'Transaction Header Segment incorrectly Formatted',
    452: 'Claim Segment incorrectly Formatted',
    453: 'Pharmacy Provider Segment incorrectly Formatted',
    454: 'Prescriber Segment incorrectly Formatted',
    455: 'Workers\u2019 Compensation Segment incorrectly Formatted',
    456: 'Pricing Segment incorrectly Formatted',
    457: 'Coupon Segment incorrectly Formatted',
    458: 'Prior Authorization Segment incorrectly Formatted',
    459: 'Facility Segment incorrectly Formatted',
    460: 'Narrative Segment incorrectly Formatted',
    461: 'Purchaser Segment incorrectly Formatted',
    462: 'Service Provider Segment incorrectly Formatted',
    463: 'Pharmacy not Contracted in Assisted Living Network',
    464: 'Service Provider ID Qualifier does not precede Service Provider ID',
    465: 'Patient ID Qualifier does not precede Patient ID',
    466: 'Prescription/Service Reference Number Qualifier does not precede Prescription/Service Reference Number',
    467: 'Product/Service ID Qualifier does not precede Product/Service ID',
    468: 'Procedure Modifier Code Count does not precede Procedure Modifier Code',
    469: 'Submission Clarification Code Count does not precede Submission Clarification Code',
    470: 'Originally Prescribed Product/Service ID Qualifier does not precede Originally Prescribed Product/Service Code',
    471: 'Other Amount Claimed Submitted Count does not precede Other Amount Claimed Amount and/or Qualifier',
    472: 'Other Amount Claimed Submitted Qualifier does not precede Other Amount Claimed Submitted',
    473: 'Provider ID Qualifier does not precede Provider ID',
    474: 'Prescriber ID Qualifier does not precede Prescriber ID',
    475: 'Primary Care Provider ID Qualifier does not precede Primary Care Provider ID',
    476: 'Coordination of Benefits/Other Payments Count does not precede Other Payer Coverage Type',
    477: 'Other Payer ID Count does not precede Other Payer ID Data Fields',
    478: 'Other Payer ID Qualifier does not precede Other Payer ID',
    479: 'Other Payer Amount Paid Count does not precede Other Payer Amount Paid and/or Qualifier',
    480: 'Other Payer Amount Paid Qualifier does not precede Other Payer Amount Paid',
    481: 'Other Payer Reject Count does not precede Other Payer Reject Code',
    482: 'Other Payer-Patient Responsibility Amount Count does not precede Other Payer-Patient Responsibility Amount and/or Qualifier',
    483: 'Other Payer-Patient Responsibility Amount Qualifier does not precede Other Payer-Patient Responsibility Amount',
    484: 'Benefit Stage Count does not precede Benefit Stage Amount and/or Qualifier',
    485: 'Benefit Stage Qualifier does not precede Benefit Stage Amount',
    486: 'Pay to Qualifier does not precede Pay to ID',
    487: 'Generic Equivalent Product ID Qualifier does not precede Generic Equivalent Product ID',
    488: 'DUR/PPS Code Counter does not precede DUR Data Fields',
    489: 'DUR Co-Agent ID Qualifier does not precede DUR Co-Agent ID',
    490: 'Compound Ingredient Component Count does not precede Compound Product ID and/or Qualifier',
    491: 'Compound Product ID Qualifier does not precede Compound Product ID',
    492: 'Compound Ingredient Modifier Code Count does not precede Compound Ingredient Modifier Code',
    493: 'Diagnosis Code Count does not precede Diagnosis Code and/or Product ID Qualifier',
    494: 'Diagnosis Code Qualifier does not precede Diagnosis Code',
    495: 'Clinical Information Counter does not precede Clinical Measurement Data',
    496: 'Length of Need Qualifier does not precede Length of Need',
    497: 'Question Number/Letter Count does not precede Question Number/Letter',
    498: 'Accumulator Month Count does not precede Accumulator Month',
    '5C': 'Missing or Invalid Other Payer Coverage Type',
    '5E': 'Missing or Invalid Other Payer Reject Count',
    '5J': 'Missing or Invalid Facility City Address',
    50: 'Non-Matched Pharmacy Number',
    51: 'Missing or Incorrect RxGRP',
    52: 'Incorrect Member ID',
    53: 'Non-Matched Person Code',
    54: 'Non-Matched Product/Service ID Number',
    55: 'Non-Matched Product Package Size',
    56: 'Non-Matched Prescriber ID',
    58: 'Non-Matched Primary Prescriber',
    504: 'Benefit Stage Qualifier Value not Supported',
    505: 'Other Payer Coverage Type Value not Supported',
    506: 'Prescription/Service Reference Number Qualifier Value not Supported',
    507: 'Additional Documentation Type ID Value not Supported',
    508: 'Authorized Representative State/Province Address Value not Supported',
    509: 'Basis of Request Value not Supported',
    510: 'Billing Entity Type Indicator Value not Supported',
    511: 'CMS Part D Defined Qualified Facility Value not Supported',
    512: 'Compound Code Value not Supported',
    513: 'Compound Dispensing Unit Form Indicator Value not Supported',
    514: 'Compound Ingredient Basis of Cost Determination Value not Supported',
    515: 'Compound Product ID Qualifier Value not Supported',
    516: 'Compound Type Value not Supported',
    517: 'Coupon Type Value not Supported',
    518: 'DUR Co-Agent ID Qualifier Value not Supported',
    519: 'DUR/PPS Level of Effort Value not Supported',
    520: 'Delay Reason Code Value not Supported',
    521: 'Diagnosis Code Qualifier Value not Supported',
    522: 'Dispensing Status Value not Supported',
    523: 'Eligibility Clarification Code Value not Supported',
    524: 'Employer State/ Province Address Value not Supported',
    525: 'Facility State/Province Address Value not Supported',
    526: 'Header Response Status Value not Supported',
    527: 'Intermediary Authorization Type ID Value not Supported',
    528: 'Length of Need Qualifier Value not Supported',
    529: 'Level of Service Value not Supported',
    530: 'Measurement Dimension Value not Supported',
    531: 'Measurement Unit Value not Supported',
    532: 'Medicaid Indicator Value not Supported',
    533: 'Originally Prescribed Product/Service ID Qualifier Value not Supported',
    534: 'Other Amount Claimed Submitted Qualifier Value not Supported',
    535: 'Other Coverage Code Value not Supported',
    536: 'Other Payer-Patient Responsibility Amount Qualifier Value not Supported',
    537: 'Patient Assignment Indicator (Direct Member Reimbursement Indicator) Value not Supported',
    538: 'Patient Gender Code Value not Supported',
    539: 'Patient State/Province Address Value not Supported',
    540: 'Pay to State/ Province Address Value not Supported',
    541: 'Percentage Sales Tax Basis Submitted Value not Supported',
    542: 'Pregnancy Indicator Value not Supported',
    543: 'Prescriber ID Qualifier Value not Supported',
    544: 'Prescriber State/Province Address Value not Supported',
    545: 'Prescription Origin Code Value not Supported',
    546: 'Primary Care Provider ID Qualifier Value not Supported',
    547: 'Prior Authorization Type Code Value not Supported',
    548: 'Provider Accept Assignment Indicator Value not Supported',
    549: 'Provider ID Qualifier Value not Supported',
    550: 'Request Status Value not Supported',
    551: 'Request Type Value not Supported',
    552: 'Route of Administration Value not Supported',
    553: 'Smoker/Non-Smoker Code Value not Supported',
    554: 'Special Packaging Indicator Value not Supported',
    555: 'Transaction Count Value not Supported',
    556: 'Unit of Measure Value not Supported',
    557: 'COB Segment Present on a Non-COB Claim',
    558: 'Part D Plan cannot coordinate Benefits with Another Part D Plan',
    559: 'ID Submitted is associated with a Sanctioned Pharmacy',
    560: 'Pharmacy not Contracted in Retail Network',
    561: 'Pharmacy not Contracted in Mail Order Network',
    562: 'Pharmacy not Contracted in Hospice Network',
    563: 'Pharmacy not Contracted in Veterans Administration Network',
    564: 'Pharmacy not Contracted in Military Network',
    565: 'Patient Country Code Value not Supported',
    566: 'Patient Country Code not used for this Transaction',
    567: 'M/I Veterinary Use Indicator',
    568: 'Veterinary Use Indicator Value not Supported',
    569: 'Provide Notice: Medicare Prescription Drug Coverage and Your Rights',
    570: 'Veterinary Use Indicator not used for this Transaction',
    571: 'Patient ID Associated State/Province Address Value not Supported',
    572: 'Medigap ID not Covered',
    573: 'Prescriber Alternate ID Associated State/Province Address Value not Supported',
    574: 'Compound Ingredient Modifier Code not Covered',
    575: 'Purchaser State/Province Address Value not Supported',
    576: 'Service Provider State/Province Address Value not Supported',
    577: 'Missing or Invalid Other Payer ID',
    578: 'Other Payer ID Count does not match Number of Repetitions',
    579: 'Other Payer ID Count exceeds Number of Occurrences Supported',
    580: 'Other Payer ID Count Grouping Incorrect',
    581: 'Other Payer ID Count is not used for this Transaction Code',
    582: 'Missing or Invalid Fill Number',
    583: 'Provider ID not Covered',
    584: 'Purchaser ID Associated State/Province Code Value not Supported',
    585: 'Fill Number Value not Supported',
    586: 'Facility ID not Covered',
    587: 'Carrier ID not Covered',
    588: 'Alternate ID not Covered',
    589: 'Patient ID not Covered',
    590: 'Compound Dosage Form not Covered',
    591: 'Plan ID not Covered',
    592: 'DUR Co-Agent ID not Covered',
    593: 'Missing or Invalid Date of Service',
    594: 'Pay to ID not Covered',
    595: 'Associated Prescription/Service Provider ID not Covered',
    596: 'Compound Preparation Time not used for this Transaction Code',
    597: 'LTC Dispensing Type does not support the Packaging Type',
    598: 'More Than One Patient Found',
    599: 'Cardholder ID Matched but Last Name did not',
    '6C': 'Missing or Invalid Other Payer ID Qualifier',
    '6D': 'Missing or Invalid Facility Zip/Postal Zone',
    '6E': 'Missing or Invalid Other Payer Reject Code',
    '6G': 'Coordination of Benefits/Other Payments Segment Required for Adjudication',
    '6H': 'Coupon Segment Required for Adjudication',
    '6J': 'Insurance Segment Required for Adjudication',
    '6K': 'Patient Segment Required for Adjudication',
    '6M': 'Pharmacy Provider Segment Required for Adjudication',
    '6N': 'Prescriber Segment Required for Adjudication',
    '6P': 'Pricing Segment Required for Adjudication',
    '6Q': 'Prior Authorization Segment Required for Adjudication',
    '6R': 'Worker\u2019s Compensation Segment Required for Adjudication',
    '6S': 'Transaction Segment Required for Adjudication',
    '6T': 'Compound Segment Required for Adjudication',
    '6U': 'Compound Segment incorrectly Formatted',
    '6V': 'Multi-ingredient Compounds not Supported',
    '6W': 'DUR/PPS Segment Required for Adjudication',
    '6X': 'DUR/PPS Segment incorrectly Formatted',
    '6Y': 'Not Authorized to Submit electronically',
    '6Z': 'Provider not Eligible to perform Service/Dispense Product',
    60: 'Product/Service not covered for Patient Age',
    61: 'Product/Service not covered for Patient Gender',
    62: 'Patient/Card Holder ID Name Mismatch',
    63: 'Institutionalized Patient Product/Service ID not Covered',
    64: 'Claim Submitted does not match Prior Authorization',
    65: 'Patient is not Covered',
    66: 'Patient Age exceeds Maximum Age',
    67: 'Filled before Coverage Effective',
    68: 'Filled after Coverage Expired',
    69: 'Filled after Coverage Terminated',
    600: 'Coverage Outside Submitted Date of Service',
    601: 'Intermediary Authorization Type ID does not precede Intermediary Authorization ID',
    602: 'Associated Prescription/Service Provider ID Qualifier does not precede Associated Prescription/ Service Provider ID',
    603: 'Prescriber Alternate ID Qualifier does not precede Prescriber Alternate ID',
    604: 'Purchaser ID Qualifier does not precede Purchaser ID',
    605: 'Seller ID Qualifier does not precede Seller ID',
    606: 'Brand Drug / Specific Labeler Code Required',
    607: 'Information Reporting (N1/N3) Transaction cannot be matched to a Claim (B1/B3)',
    608: 'Step Therapy',
    609: 'COB Claim not Required, Patient Liability Amount Submitted was Zero',
    610: 'Information Reporting Transaction (N1/N3) Matched to Reversed or Rejected Claim Submitted under Part D BIN PCN',
    611: 'Information Reporting Transaction (N1/N3) was matched to a Claim Submitted under The Part D BIN/PCN Paid as Enhanced or OTC or by a Benefit Other than Part D',
    612: 'LTC Appropriate Dispensing Invalid Submission Clarification Code (SCC) Combination',
    613: 'The Packaging Methodology or Dispensing Frequency is Missing or Inappropriate for LTC Short Cycle',
    614: 'Uppercase Character(s) Required',
    615: 'Compound Ingredient Basis of Cost Determination Value 14 Required when Compound Ingredient Quantity is 0 but Cost is Greater than $0',
    616: 'Submission Clarification Code 8 Required when Compound Ingredient Quantity is 0',
    617: 'Compound Ingredient Drug Cost cannot be Negative Amount',
    618: "Plan's Prescriber Data Base indicates the Submitted Prescriber\u2019s DEA does not allow this Drug DEA Schedule",
    619: 'Prescriber Type 1 NPI Required',
    620: 'This Product/Service may be covered under Medicare Part D',
    621: 'This Medicaid Patient is Medicare Eligible',
    622: 'COB Claim not Required, Patient Liability Amount Submitted was Zero',
    623: 'Missing or Invalid Authorized Representative Country Code',
    624: 'Missing or Invalid Employer Country Code',
    625: 'Missing or Invalid Entity Country Code',
    627: 'Missing or Invalid Facility Country Code',
    628: 'Missing or Invalid Patient ID Associated Country Code',
    629: 'Missing or Invalid Pay to Country Code',
    630: 'Missing or Invalid Prescriber Alternate ID Associated Country Code',
    631: 'Missing or Invalid Prescriber ID Associated Country Code',
    632: 'Missing or Invalid Prescriber Country Code',
    633: 'Missing or Invalid Purchaser ID Associated Country Code',
    634: 'Authorized Representative Country Code Value not Supported',
    635: 'Missing or Invalid Employer Country Code Value not Supported',
    637: 'Missing or Invalid Entity Country Code Value not Supported',
    638: 'Missing or Invalid Facility Country Code Value not Supported',
    639: 'Missing or Invalid Patient ID Associated Country Code Value not Supported',
    640: 'Missing or Invalid Pay to Country Code Value not Supported',
    641: 'Missing or Invalid Prescriber Alternate ID Associated Country Code Value not Supported',
    642: 'Missing or Invalid Prescriber ID Associated Country Code Value not Supported',
    643: 'Missing or Invalid Prescriber Country Code Value not Supported',
    644: 'Missing or Invalid Purchaser ID Associated Country Code Value not Supported',
    645: 'Repackaged product is not covered by the Contract',
    646: 'Patient not Eligible due to Non Payment of Premium. Patient to Contact Plan',
    647: 'Quantity Prescribed Required for CII Prescription',
    648: 'Quantity Prescribed does not match Quantity Prescribed on Original CII Dispensing',
    649: 'Cumulative Quantity for this CII Rx Number exceeds Quantity Prescribed',
    650: 'Fill Date greater than 60 Days from CII Date Prescription Written (414-DE)',
    651: 'REMS: Mandatory Data Element(s) Missing',
    652: 'REMS: Prescriber not Matched or may not be Enrolled',
    653: 'REMS: Patient not Matched or may not be Enrolled',
    654: 'REMS: Pharmacy not Matched or may not be Enrolled',
    655: 'REMS: Multiple Patient Matches',
    656: 'REMS: Patient Age not Matched',
    657: 'REMS: Patient Gender not Matched',
    658: 'REMS: Pharmacy has not enrolled',
    659: 'REMS: Pharmacy has not renewed Enrollment',
    660: 'REMS: Pharmacy has not submitted Agreement Form',
    661: 'REMS: Pharmacy has been suspended due to Non-compliance',
    662: 'REMS: Prescriber has not enrolled',
    663: 'REMS: Prescriber has not completed a Knowledge Assessment',
    664: 'REMS: Prescriber has been suspended due to Non-compliance',
    665: 'REMS: Excessive Days Supply',
    666: 'REMS: Insufficient Days Supply',
    667: 'REMS: Excessive Dosage',
    668: 'REMS: Insufficient Dosage',
    669: 'REMS: Refills not Permitted',
    670: 'REMS: Laboratory Test Results not Documented',
    671: 'REMS: Laboratory Test not Conducted within Specified Time Period',
    672: 'REMS: Dispensing not Authorized due to Laboratory Test Results',
    673: 'REMS: Prescriber \u0421ounseling of Patient not Documented',
    674: 'REMS: Prescriber has not documented Safe Use Conditions',
    675: 'REMS: Prescriber has not documented Patient Opioid Tolerance',
    676: 'REMS: Prescriber has not documented Patient Contraceptive Use',
    677: 'REMS: Lack of Contraindicated Therapy not Documented',
    678: 'REMS: Step Therapy not Documented',
    679: 'REMS: Prescriber has not Enrolled Patient',
    680: 'REMS: Prescriber must renew Patient Enrollment',
    681: 'REMS: Patient Enrollment Requirements have not been met',
    682: 'REMS: Prescriber has not submitted Patient Agreement',
    683: 'REMS: Prescriber has not verified Patient\u2019s Reproductive Potential',
    684: 'REMS: Patient has not documented Safe Use Conditions',
    685: 'REMS: Patient has not documented Completed Education',
    686: 'REMS: Patient has not documented Contraceptive Use',
    687: 'REMS Administrator Denied',
    688: 'REMS: Service Billing Denied',
    689: 'PDMP Administrator Denied',
    690: 'PDMP: Pharmacy not Contracted',
    691: 'PDMP: Pharmacy Contract not Renewed',
    692: 'PDMP: Missing or Invalid Patient First Name',
    693: 'PDMP: Missing or Invalid Patient Last Name',
    694: 'PDMP: Missing or Invalid Patient Street Address',
    695: 'PDMP: Missing or Invalid Patient City',
    696: 'PDMP: Missing or Invalid Patient State or Province',
    697: 'PDMP: Missing or Invalid Patient ZIP/Postal Code',
    698: 'PDMP: Missing or Invalid Prescriber ID',
    699: 'PDMP: Missing or Invalid Prescriber Last Name',
    '7A': 'Provider does not match Authorization on File',
    '7B': 'Service Provider ID Qualifier Value not Supported for Processor/Payer',
    '7C': 'Missing or Invalid Other Payer ID',
    '7D': 'Non-Matched DOB',
    '7E': 'Missing or Invalid DUR/PPS Code Counter',
    '7F': 'Future Date not Allowed for Date of Birth',
    '7G': 'Future Date not Allowed for DOB',
    '7H': 'Non-Matched Gender Code',
    '7J': 'Patient Relationship Code Value not Supported',
    '7K': 'Discrepancy Between Other Coverage Code and Other Payer Amount',
    '7M': 'Discrepancy Between Other Coverage Code and Other Coverage Information on File',
    '7N': 'Patient ID Qualifier Value not Supported',
    '7P': 'Coordination of Benefits/Other Payments Count exceeds Number of Supported Payers',
    '7Q': 'Other Payer ID Qualifier Value not Supported',
    '7R': 'Other Payer Amount Paid Count exceeds Number of Supported Groupings',
    '7S': 'Other Payer Amount Paid Qualifier Value not Supported',
    '7T': 'Quantity Intended to be Dispensed Required for Partial Fill Transaction',
    '7U': 'Days Supply Intended to be Dispensed Required for Partial Fill Transaction',
    '7V': 'Duplicate Refills',
    '7W': 'Refills exceed Allowable Refills',
    '7X': 'Days Supply exceeds Plan Limitation',
    '7Y': 'Compounds not Covered',
    '7Z': 'Compound Requires two or more Ingredients',
    70: 'Product/Service Not Covered – Plan/Benefit Exclusion',
    71: 'Prescriber is not covered',
    72: 'Primary Prescriber is not covered',
    73: 'Refills are not covered',
    74: 'Other Carrier Payment meets or exceeds Payable',
    75: 'Prior Authorization Required',
    76: 'Plan Limitations Exceeded',
    77: 'Discontinued Product/Service ID Number',
    78: 'Cost exceeds Maximum',
    79: 'Refill too Soon',
    700: 'PDMP: Missing or Invalid Patient ID',
    701: 'PDMP: Missing or Invalid Patient Date of Birth',
    702: 'PDMP: Missing or Invalid Patient Gender',
    703: 'PDMP: Missing or Invalid Prescription Origin Code',
    704: 'PDMP: Missing or Invalid Scheduled Rx Serial Number',
    705: 'PDMP: Missing or Invalid Product/Service ID',
    706: 'PDMP: Missing or Invalid Compound Code',
    707: 'PDMP: Missing or Invalid Patient Phone Number',
    708: 'PDMP: Missing or Invalid Payment Code',
    769: 'Paid Billing Transaction (B1/B3) Submitted under the Part D BIN PCN Found but Information Reporting Reversal (N2) cannot be matched to an Information Reporting Transaction (N1/N3) in an Approved Status; Reversal (N2) not Processed.',
    770: 'And Information Reporting Reversal (N2) cannot be matched to an Information Reporting Transaction (N1/N3) in Approved Status; Reversal (N2) not Processed',
    771: 'Compound contains Unidentifiable Ingredient(s); Submission Clarification Code Override not Allowed',
    772: 'Compound not Payable due to Non-covered Ingredient(s); Submission Clarification Code Override not Allowed',
    773: 'Prescriber is not listed on Medicare Enrollment File',
    774: 'Prescriber Medicare Enrollment Period is outside of Claim Date of Service',
    775: 'Pharmacy not listed within Medicare Fee for Service Active Enrollment File',
    776: 'Pharmacy Enrollment with Medicare Fee for Service has terminated',
    777: "Plan's Prescriber Data Base not able to verify Active State License with Prescriptive Authority for Prescriber ID Submitted",
    '8A': 'Compound Requires at least one Covered Ingredient',
    '8B': 'Compound Segment Missing on a Compound Claim',
    '8C': 'Missing or Invalid Facility ID',
    '8D': 'Compound Segment Present on a Non-Compound Claim',
    '8E': 'Missing or Invalid DUR/PPS Level of Effort',
    '8G': 'Product/Service ID (407-D7) must be a Single Zero \u201c0\u201d for Compounds',
    '8H': 'Product/Service only Covered on Compound Claim',
    '8J': 'Incorrect Product/Service ID for Processor/Payer',
    '8K': 'DAW Code Value not Supported',
    '8M': 'Sum of Compound Ingredient Costs does not Equal Ingredient Cost Submitted',
    '8N': 'Future Date Prescription Written not Allowed',
    '8P': 'Date Written Different on previous Filling',
    '8Q': 'Excessive Refills Authorized',
    '8R': 'Submission Clarification Code Value not Supported',
    '8S': 'Basis of Cost Determination Value not Supported',
    '8T': 'U&C must be Greater than Zero',
    '8U': 'GAD must be Greater than Zero',
    '8V': 'Negative Dollar Amount is not supported in the Other Payer Amount Paid Field',
    '8W': 'Discrepancy Between Other Coverage Code and Other Payer Amount Paid',
    '8X': 'Collection from Cardholder not Allowed',
    '8Y': 'Excessive Amount Collected',
    '8Z': 'Product/Service ID Qualifier Value not Supported',
    80: 'Drug-Diagnosis Mismatch',
    81: 'Claim too Old',
    82: 'Claim is Post-Dated',
    83: 'Duplicate Paid/Captured Claim',
    84: 'Claim has not been Paid/Captured',
    85: 'Claim not Processed',
    86: 'Submit Manual Reversal',
    87: 'Reversal not Processed',
    88: 'DUR Reject Error',
    89: 'Rejected Claim Fees Paid',
    816: 'Pharmacy Benefit Exclusion, may be covered under Patient\u2019s Medical Benefit',
    817: 'Pharmacy Benefit Exclusion, covered under Patient\u2019s Medical Benefit',
    818: 'Medication Administration not Covered, Plan Benefit Exclusion',
    819: 'Plan Enrollment File indicates Medicare as Primary Coverage',
    820: 'Information Reporting Transaction (N1/N3) Matched to Reversed or Rejected Claim not Submitted under Part D BIN PCN',
    821: 'Information Reporting (N1/N3) Transaction Matched to Paid Claim not Submitted under Part D BIN PCN',
    822: 'Drug is Unrelated to the Terminal Illness and/or Related Conditions. Not Covered under Hospice.',
    823: 'Drug is Beneficiary\u2019s Liability - Not Covered by Hospice or Part D. Hospice Non-Formulary. Check Other Coverage',
    824: 'Multi-transaction Transmission not Allowed in Current NCPDP Standard',
    825: 'Claim Date of Service is Outside of Product\u2019s FDA/NSDE Marketing Dates',
    826: 'Prescriber NPI Submitted not Found within Processor\u2019s NPI File',
    827: 'Pharmacy Service Provider is temporarily Suspended from Processing Claims by Payer/Processor',
    828: 'Plan/Beneficiary Case Management Restriction in Place',
    829: 'Pharmacy must Notify Beneficiary: Claim not Covered due to Failure to meet Medicare Part D Active, Valid Prescriber NPI Requirements',
    830: 'Workers\u2019 Comp or P&C Adjuster Authorization Required \u2013 Patient must directly contact their Adjuster',
    831: 'Product Service ID Carve-Out, Bill Medicaid Fee for Service',
    832: 'Prescriber NPI not Found, therefore NPI Active Status, MEDICARE Enrollment, Prescriptive Authority could not be validated',
    833: 'Accumulator Year is not within ATBT Timeframe',
    834: 'Missing or Invalid Provider First Name',
    835: 'Missing or Invalid Provider Last Name',
    836: 'Missing or Invalid Facility ID Qualifier',
    837: 'Facility ID Value not Supported',
    838: 'Missing or Invalid Original Manufacturer Product ID',
    839: 'Missing or Invalid Original Manufacturer Product ID Qualifier',
    840: 'Original Manufacturer Product ID Value not Supported',
    844: 'Missing or Invalid Other Payer Adjudicated Program Type',
    845: 'Other Payer Reconciliation ID is not used for this Transaction Code',
    846: 'Benefit Stage Indicator Count is not used for this Transaction Code',
    847: 'Benefit Stage Indicator Count does not precede Benefit Stage Indicator',
    848: 'Missing or Invalid Benefit Stage Indicator Count',
    849: 'Benefit Stage Indicator Count does not match Number of Repetitions',
    850: 'Benefit Stage Indicator is not used for this Transaction Code',
    851: 'Benefit Stage Indicator Value not Supported',
    852: 'Missing or Invalid Benefit Stage Indicator',
    853: 'N Payer IIN is not used for this Transaction Code',
    854: 'Missing or Invalid N Payer IIN',
    855: 'Non-Matched N Payer IIN',
    856: 'N Payer Processor Control Number is not used for this Transaction Code',
    857: 'Missing or Invalid N Payer Processor Control Number',
    858: 'Non-Matched N Payer Processor Control Number',
    859: 'N Payer Group ID is not used for this Transaction Code',
    860: 'Missing or Invalid N Payer Group ID',
    861: 'Non-Matched N Payer Group ID',
    862: 'N Payer Cardholder ID is not used for this Transaction Code',
    863: 'Missing or Invalid N Payer Cardholder ID',
    864: 'N Payer Cardholder ID is not Covered',
    865: 'N Payer Adjudicated Program Type is not used for this Transaction Code',
    866: 'Missing or Invalid N Payer Adjudicated Program Type',
    867: 'N Payer Adjudicated Program Type Value not Supported',
    868: 'Missing or Invalid N Transaction Reconciliation ID',
    869: 'Missing or Invalid N Transaction Source Type',
    870: 'Missing or Invalid Prescriber DEA Number',
    871: 'Missing or Invalid Compound Level of Complexity',
    872: 'Mismatch Between Compound Level of Complexity and Preparation Environment Type',
    873: 'Missing or Invalid Preparation Environment Type',
    874: 'Missing or Invalid Preparation Environment Event Code',
    875: 'Missing or Invalid Total Prescribed Quantity Remaining',
    876: 'Prescriptive Authority Restrictions Apply, Criteria not Met',
    877: 'Service Provider ID Terminated on NPPES File',
    878: 'Service Provider ID Not Found on NPPES File',
    879: 'Service Provider ID Excluded from Receiving CMS Enrollment Data',
    880: 'Missing or Invalid Submission Type Code',
    881: 'Missing Submission Type Code Count',
    882: 'Missing or Invalid do not dispense Before Date',
    883: 'Date of Service Prior to do not dispense Before Date',
    884: 'Missing or Invalid Multiple RX Order Group Reason Code',
    885: 'Missing or Invalid Multiple RX Order Group ID',
    886: 'Missing or Invalid Prescriber Place of Service',
    887: 'A Previous Payer(s) is an Excluded Federal Health Care Program Copay Assistance is not allowed',
    888: 'Beneficiary is Enrolled in Excluded Federal Health Care Program',
    889: 'Prescriber not Enrolled in State Medicaid Program',
    890: 'Pharmacy not Enrolled in State Medicaid Program',
    891: 'Days Supply is less than Plan Minimum',
    892: 'Pharmacy must attest FDA REMS Requirements have been met',
    893: 'Pharmacy must attest Required Patient Form is on File',
    894: 'Pharmacy must attest Plan Medical Necessity Criteria has been met',
    895: 'Allowed Number of Overrides Exhausted',
    896: 'Other Adjudicated Program Type of Unknown is not Covered',
    897: 'Missing or Invalid Regulatory Fee Count',
    898: 'Missing or Invalid Regulatory Fee Type Code',
    899: 'Missing or Invalid Other Payer Percentage Tax Exempt Indicator',
    '9B': 'Reason for Service Code Value not Supported',
    '9C': 'Professional Service Code Value not Supported',
    '9D': 'Result of Service Code Value not Supported',
    '9E': 'Quantity does not match Dispensing Unit',
    '9G': 'Quantity Dispensed exceeds Maximum Allowed',
    '9H': 'Quantity not Valid for Product/Service ID Submitted',
    '9J': 'Future Other Payer Date not Allowed',
    '9K': 'Compound Ingredient Component Count exceeds Number of Ingredients Supported',
    '9M': 'Minimum of Two Ingredients Required',
    '9N': 'Compound Ingredient Quantity exceeds Maximum Allowed',
    '9P': 'Compound Ingredient Drug Cost must be greater than Zero',
    '9Q': 'Route of Administration Submitted not Covered',
    '9R': 'Prescription/Service Reference Number Qualifier Submitted not Covered',
    '9S': 'Future Associated Prescription/Service Date not Allowed',
    '9T': 'Prior Authorization Type Code Submitted not Covered',
    '9U': 'Provider ID Qualifier Submitted not Covered',
    '9V': 'Prescriber ID Qualifier Submitted not Covered',
    '9W': 'DUR/PPS Code Counter exceeds Number of Occurrences Supported',
    '9X': 'Coupon Type Submitted not Covered',
    '9Y': 'Compound Product ID Qualifier Submitted not Covered',
    '9Z': 'Duplicate Product ID in Compound',
    90: 'Host Hung Up',
    91: 'Host Response Error',
    92: 'System Unavailable/Host Unavailable',
    95: 'Time Out',
    96: 'Scheduled Downtime',
    97: 'Payer Unavailable',
    98: 'Connection to Payer is down',
    99: 'Host Processing Error',
    900: 'Missing or Invalid Other Payer Regulatory Fee Type Count',
    901: 'Missing or Invalid Other Payer Regulatory Fee Type Code',
    902: 'Missing or Invalid Other Payer Regulatory Fee Exempt Indicator',
    903: 'Regulatory Fee Count not used for this Transaction Code',
    904: 'Regulatory Fee Type Code not used for this Transaction Code',
    905: 'Other Payer Percentage Tax Exempt Indicator not used for this Transaction Code',
    906: 'Other Payer Regulatory Fee Type Count not used for this Transaction Code',
    907: 'Other Payer Regulatory Fee Type Code not used for this Transaction Code',
    908: 'Other Payer Regulatory Fee Exempt Indicator not used for this Transaction Code',
    909: 'Regulatory Fee Grouping not Correct',
    910: 'Other Payer Regulatory Fee Grouping not Correct',
    911: 'Regulatory Fee Count does not match Number of Repetitions',
    912: 'Other Payer Regulatory Fee Type Count does not match Number of Repetitions',
    913: 'Regulatory Fee Count exceeds Number of Occurrences Supported',
    914: 'Other Payer Regulatory Fee Type Count exceeds Number 0f Occurrences Supported',
    915: 'Regulatory Fee Type Code does not precede Regulatory Fee Amount Submitted',
    916: 'Other Payer Regulatory Fee Type Code does not precede Other Payer Regulatory Fee Exempt Indicator',
    917: 'Regulatory Fee Count does not precede Regulatory Fee Type Code',
    918: 'Other Payer Regulatory Fee Type Count does not precede Other Payer Regulatory Fee Type Code',
    919: 'Regulatory Fee Type Code Value not Supported',
    920: 'Other Payer Regulatory Fee Type Code Value not Supported',
    921: 'Other Payer Regulatory Fee Exempt Indicator Value not Supported',
    932: 'Missing or Invalid Patient Middle Name',
    933: 'Missing or Invalid Patient Name Suffix',
    934: 'Missing or Invalid Patient Name Prefix',
    938: 'Missing or Invalid Prescriber Middle Name',
    942: 'Missing or Invalid Species',
    943: 'DUR Reject \u2013 Pharmacy Override using DUR/PPS not Allowed',
    944: 'All Lots of Drug/Product Recalled',
    945: 'High Dollar Amount is not Supported',
    AA: 'Patient Spenddown not Met',
    AB: 'Date Written is after Date Filled',
    AC: 'Product not Covered Non-Participating Manufacturer',
    AD: 'Billing Provider not Eligible to bill this Claim Type',
    AE: 'QMB (Qualified Medicare Beneficiary)-Bill Medicare',
    AF: 'Patient Enrolled under Managed Care',
    AG: 'Days Supply Limitation for Product/Service',
    AH: 'Unit Dose Packaging only Payable for Nursing Home Recipients',
    AJ: 'Generic Drug Required',
    AK: 'Missing or Invalid Software Vendor/Certification ID',
    AM: 'Missing or Invalid Segment Identification',
    AQ: 'Missing or Invalid Facility Segment',
    A1: 'ID Submitted is associated with a Sanctioned Prescriber',
    A2: 'ID Submitted is associated to a Deceased Prescriber',
    A3: 'This Product may be covered under Hospice \u2013 Medicare A',
    A4: 'This Product may be covered under the Medicare- B Bundled Payment to an ESRD',
    A5: 'Not Covered under Part D Law',
    A6: 'This Product/Service may be covered under Medicare Part B',
    A7: 'Missing or Invalid Internal Control Number',
    A9: 'Missing or Invalid Transaction Count',
    BA: 'Compound Basis of Cost Determination Submitted not Covered',
    BB: 'Diagnosis Code Qualifier Submitted not Covered',
    BC: 'Future Measurement Date not Allowed',
    BE: 'Missing or Invalid Professional Service Fee Submitted',
    BM: 'Missing or Invalid Narrative Message',
    B2: 'Missing or Invalid Service Provider ID Qualifier',
    CA: 'Missing or Invalid Patient First Name',
    CB: 'Missing or Invalid Patient Last Name',
    CC: 'Missing or Invalid Cardholder First Name',
    CD: 'Missing or Invalid Cardholder Last Name',
    CE: 'Missing or Invalid Home Plan',
    CF: 'Missing or Invalid Employer Name',
    CG: 'Missing or Invalid Employer Street Address',
    CH: 'Missing or Invalid Employer City Address',
    CI: 'Missing or Invalid Employer State/Province Address',
    CJ: 'Missing or Invalid Employer Zip Postal Zone',
    CK: 'Missing or Invalid Employer Phone Number',
    CL: 'Missing or Invalid Employer Contact Name',
    CM: 'Missing or Invalid Patient Street Address',
    CN: 'Missing or Invalid Patient City Address',
    CO: 'Missing or Invalid Patient State/Province Address',
    CP: 'Missing or Invalid Patient Zip/Postal Zone',
    CQ: 'Missing or Invalid Patient Phone Number',
    CR: 'Missing or Invalid Carrier ID',
    CW: 'Missing or Invalid Alternate ID',
    CX: 'Missing or Invalid Patient ID Qualifier',
    CY: 'Missing or Invalid Patient ID',
    CZ: 'Missing or Invalid Employer ID',
    DC: 'Missing or Invalid Dispensing Fee Submitted',
    DN: 'Missing or Invalid Basis of Cost Determination',
    DQ: 'Missing or Invalid Usual and Customary Charge',
    DR: 'Missing or Invalid Prescriber Last Name',
    DT: 'Missing or Invalid Unit Dose Indicator',
    DU: 'Missing or Invalid Gross Amount Due',
    DV: 'Missing or Invalid Other Payer Amount Paid',
    DX: 'Missing or Invalid Patient Paid Amount Submitted',
    DY: 'Missing or Invalid Date of Injury',
    DZ: 'Missing or Invalid Claim/Reference ID',
    EA: 'Missing or Invalid Originally Prescribed Product/Service Code',
    EB: 'Missing or Invalid Originally Prescribed Quantity',
    EC: 'Missing or Invalid Compound Ingredient Component Count',
    ED: 'Missing or Invalid Compound Ingredient Quantity',
    EE: 'Missing or Invalid Compound Ingredient Drug Cost',
    EF: 'Missing or Invalid Compound Dosage Form Description Code',
    EG: 'Missing or Invalid Compound Dispensing Unit Form Indicator',
    EJ: 'Missing or Invalid Originally Prescribed Product/Service ID Qualifier',
    EK: 'Missing or Invalid Scheduled Prescription ID Number',
    EM: 'Missing or Invalid Prescription/Service Reference Number Qualifier',
    EN: 'Missing or Invalid Associated Prescription/Service Reference Number',
    EP: 'Missing or Invalid Associated Prescription/Service Date',
    ER: 'Missing or Invalid Procedure Modifier Code',
    ET: 'Missing or Invalid Quantity Prescribed',
    EU: 'Missing or Invalid Prior Authorization Type Code',
    EV: 'Missing or Invalid Prior Authorization Number Submitted',
    EW: 'Missing or Invalid Intermediary Authorization Type ID',
    EX: 'Missing or Invalid Intermediary Authorization ID',
    EY: 'Missing or Invalid Provider ID Qualifier',
    EZ: 'Missing or Invalid Prescriber ID Qualifier',
    E1: 'Missing or Invalid Product/Service ID Qualifier',
    E2: 'M/I Route of Administration',
    E3: 'Missing or Invalid Incentive Amount Submitted',
    E4: 'Missing or Invalid Reason for Service Code',
    E5: 'Missing or Invalid Professional Service Code',
    E6: 'Missing or Invalid Result of Service Code',
    E7: 'Missing or Invalid Quantity Dispensed',
    E8: 'Missing or Invalid Other Payer Date',
    E9: 'Missing or Invalid Provider ID',
    FO: 'Missing or Invalid Plan ID',
    GE: 'Missing or Invalid Percentage Sales Tax Amount Submitted',
    G1: 'Missing or Invalid Compound Type',
    G2: 'Missing or Invalid CMS Part D Defined Qualified Facility',
    G4: 'Physician must contact Plan',
    G5: 'Pharmacist must contact Plan',
    G6: 'Pharmacy not Contracted in Specialty Network',
    G7: 'Pharmacy not Contracted in Home Infusion Network',
    G8: 'Pharmacy not Contracted in Long Term Care Network',
    G9: 'Pharmacy not Contracted in 90 Day Retail Network',
    HA: 'Missing or Invalid Flat Sales Tax Amount Submitted',
    HB: 'Missing or Invalid Other Payer Amount Paid Count',
    HC: 'Missing or Invalid Other Payer Amount Paid Qualifier',
    HD: 'Missing or Invalid Dispensing Status',
    HE: 'Missing or Invalid Percentage Sales Tax Rate Submitted',
    HF: 'Missing or Invalid Quantity Intended to be Dispensed',
    HG: 'Missing or Invalid Days Supply Intended to be Dispensed',
    HN: 'Missing or Invalid Patient E-Mail Address',
    H1: 'Missing or Invalid Measurement Time',
    H2: 'Missing or Invalid Measurement Dimension',
    H3: 'Missing or Invalid Measurement Unit',
    H4: 'Missing or Invalid Measurement Value',
    H5: 'Missing or Invalid Primary Care Provider Location Code',
    H6: 'Missing or Invalid DUR Co-Agent ID',
    H7: 'Missing or Invalid Other Amount Claimed Submitted Count',
    H8: 'Missing or Invalid Other Amount Claimed Submitted Qualifier',
    H9: 'Missing or Invalid Other Amount Claimed Submitted',
    JE: 'Missing or Invalid Percentage Sales Tax Basis Submitted',
    J9: 'Missing or Invalid DUR Co-Agent ID Qualifier',
    KE: 'Missing or Invalid Coupon Type',
    K5: 'Missing or Invalid Transaction Reference Number',
    M1: 'Patient not Covered in this Aid Category',
    M2: 'Recipient Locked In',
    M3: 'Host PA/MC Error',
    M4: 'Prescription/Service Reference Number/Time Limit Exceeded',
    M5: 'Requires Manual Claim',
    M6: 'Host Eligibility Error',
    M7: 'Host Drug File Error',
    M8: 'Host Provider File Error',
    ME: 'Missing or Invalid Coupon Number',
    MG: 'Missing or Invalid Other Payer BIN Number',
    MH: 'Missing or Invalid Other Payer Processor Control Number',
    MJ: 'Missing or Invalid Other Payer Group ID',
    MK: 'Non-Matched Other Payer BIN Number',
    MM: 'Non-Matched Other Payer Processor Control Number',
    MN: 'Non-Matched Other Payer Group ID',
    MP: 'Other Payer Cardholder ID not Covered',
    MR: 'Product not On Formulary',
    MS: 'More than 1 Cardholder Found \u2013 Narrow Search Criteria',
    MT: 'Missing or Invalid Patient Assignment Indicator (Direct Member Reimbursement Indicator)',
    MU: 'Missing or Invalid Benefit Stage Count',
    MV: 'Missing or Invalid Benefit Stage Qualifier',
    MW: 'Missing or Invalid Benefit Stage Amount',
    MX: 'Benefit Stage Count does not match Number of Repetitions',
    MZ: 'Error Overflow',
    NE: 'Missing or Invalid Coupon Value Amount',
    NN: 'Transaction Rejected at Switch or Intermediary',
    NP: 'Missing or Invalid Other Payer-Patient Responsibility Amount Qualifier',
    NQ: 'Missing or Invalid Other Payer-Patient Responsibility Amount',
    NR: 'Missing or Invalid Other Payer-Patient Responsibility Amount Count',
    NU: 'Missing or Invalid Other Payer Cardholder ID',
    NV: 'Missing or Invalid Delay Reason Code',
    NX: 'Missing or Invalid Submission Clarification Code Count',
    N1: 'Incorrect Member ID',
    N3: 'Missing or Invalid Medicaid Paid Amount',
    N4: 'Missing or Invalid Medicaid Subrogation Internal Control Number/Transaction Control Number (ICN/TCN)',
    N5: 'Missing or Invalid Medicaid ID Number',
    N6: 'Missing or Invalid Medicaid Agency Number',
    N7: 'Use Prior Authorization Code Provided during Transition Period',
    N8: 'Use Prior Authorization Code Provided for Emergency Fill',
    N9: 'Use Prior Authorization Code Provided for Level of Care Change',
    PA: 'PA Exhausted/Not Renewable',
    PB: 'Invalid Transaction Count for this Transaction Code',
    PC: 'Missing or Invalid Claim Segment',
    PD: 'Missing or Invalid Clinical Segment',
    PE: 'Missing or Invalid COB/Other Payments Segment',
    PF: 'Missing or Invalid Compound Segment',
    PG: 'Missing or Invalid Coupon Segment',
    PH: 'Missing or Invalid DUR/PPS Segment',
    PJ: 'Missing or Invalid Insurance Segment',
    PK: 'Missing or Invalid Patient Segment',
    PM: 'Missing or Invalid Pharmacy Provider Segment',
    PN: 'Missing or Invalid Prescriber Segment',
    PP: 'Missing or Invalid Pricing Segment',
    PQ: 'Missing or Invalid Narrative Segment',
    PR: 'Missing or Invalid Prior Authorization Segment',
    PS: 'Missing or Invalid Transaction Header Segment',
    PT: 'Missing or Invalid Workers\u2019 Compensation Segment',
    PV: 'Non-Matched Associated Prescription/Service Date',
    PW: 'Non-Matched Employer ID',
    PX: 'Non-Matched Other Payer ID',
    PY: 'Non-Matched Unit Form/Route of Administration',
    PZ: 'Non-Matched Unit of Measure to Product/Service ID',
    P0: 'Non-zero Value Required for Vaccine Administration',
    P1: 'Associated Prescription/Service Reference Number not Found',
    P2: 'Clinical Information Counter out of Sequence',
    P3: 'Compound Ingredient Component Count does not match Number of Repetitions',
    P4: 'Coordination of Benefits/Other Payments Count does not match Number of Repetitions',
    P5: 'Coupon Expired',
    P6: 'Date of Service Prior to Date of Birth',
    P7: 'Diagnosis Code Count does not match Number of Repetitions',
    P8: 'DUR/PPS Code Counter out of Sequence',
    P9: 'Field is Non-Repeatable',
    RA: 'PA Reversal out of Order',
    RB: 'Multiple Partials not Allowed',
    RC: 'Different Drug Entity between Partial & Completion',
    RD: 'Mismatched Cardholder/Group ID-Partial to Completion',
    RE: 'Missing or Invalid Compound Product ID Qualifier',
    RF: 'Improper Order of \u2018Dispensing Status\u2019 Code on Partial Fill Transaction',
    RG: 'Missing or Invalid Associated Prescription/Service Reference Number on Completion Transaction',
    RH: 'Missing or Invalid Associated Prescription/Service Date on Completion Transaction',
    RJ: 'Associated Partial Fill Transaction not on File',
    RK: 'Partial Fill Transaction not Supported',
    RL: 'Transitional Benefit/Resubmit Claim',
    RM: 'Completion Transaction not Permitted with same \u2018Date of Service\u2019 as Partial Transaction',
    RN: 'Plan Limits Exceeded on Intended Partial Fill Values',
    RP: 'Out of Sequence \u2018P\u2019 Reversal on Partial Fill Transaction',
    RS: 'Missing or Invalid Associated Prescription/Service Date on Partial Transaction',
    RT: 'Missing or Invalid Associated Prescription/Service Reference Number on Partial Transaction',
    RU: 'Mandatory Data Elements must occur before Optional Data Elements in a Segment',
    RV: 'Multiple Reversals per Transmission not Supported',
    R0: 'Professional Service Code of \u201cMA\u201d required for Vaccine Incentive Fee Submitted',
    R1: 'Other Amount Claimed Submitted Count does not match Number of Repetitions',
    R2: 'Other Payer Reject Count does not match Number of Repetitions',
    R3: 'Procedure Modifier Code Count does not match Number of Repetitions',
    R4: 'Procedure Modifier Code Invalid for Product/Service ID',
    R5: 'Product/Service ID must be Zero when Product/Service ID Qualifier equals \u00d86',
    R6: 'Product/Service Not Appropriate For This Location',
    R7: 'Repeating Segment not Allowed in Same Transaction',
    R8: 'Syntax Error',
    R9: 'Value in Gross Amount Due does not follow Pricing Formulae',
    S0: 'Accumulator Month Count Does Not Match Number of Repetitions',
    S1: 'Missing or Invalid Accumulator Year',
    S2: 'Missing or Invalid Transaction Identifier',
    S3: 'Missing or Invalid Accumulated Patient True out of Pocket Amount',
    S4: 'Missing or Invalid Accumulated Gross Covered Drug Cost Amount',
    S5: 'Missing or Invalid DateTime',
    S6: 'Missing or Invalid Accumulator Month',
    S7: 'Missing or Invalid Accumulator Month Count',
    S8: 'Non-Matched Transaction Identifier',
    S9: 'Missing or Invalid Financial Information Reporting Transaction Header Segment',
    SE: 'Missing or Invalid Procedure Modifier Code Count',
    SF: 'Other Payer Amount Paid Count does not match Number of Repetitions',
    SG: 'Submission Clarification Code Count does not match Number of Repetitions',
    SH: 'Other Payer-Patient Responsibility Amount Count does not match Number of Repetitions',
    SW: 'Accumulated Patient True out of Pocket must be equal to or greater than Zero',
    TE: 'Missign or Invalid Compound Product ID',
    TN: 'Emergency Fill/Resubmit Claim',
    TP: 'Level of Care Change/Resubmit Claim',
    TQ: 'Dosage exceeds Product Labeling Limit',
    TR: 'Missing or Invalid Billing Entity Type Indicator',
    TS: 'Missing or Invalid Pay to Qualifier',
    TT: 'Missing or Invalid Pay to ID',
    TU: 'Missing or Invalid Pay to Name',
    TV: 'Missing or Invalid Pay to Street Address',
    TW: 'Missing or Invalid Pay to City Address',
    TX: 'Missing or Invalid Pay to State/ Province Address',
    TY: 'Missing or Invalid Pay to Zip/Postal Zone',
    TZ: 'Missing or Invalid Generic Equivalent Product ID Qualifier',
    T0: 'Accumulator Month Count exceeds Number of Occurrences Supported',
    T1: 'Request Financial Segment Required for Financial Information Reporting',
    T2: 'Missing or Invalid Request Reference Segment',
    T3: 'Out of Order DateTime',
    T4: 'Duplicate DateTime',
    UA: 'Missing or Invalid Generic Equivalent Product ID',
    UE: 'Missing or Invaid Compound Ingredient Basis of Cost Determination',
    UU: 'DAW 0 cannot be submitted on a Multi-source Drug with available Generics',
    UZ: 'Other Payer Coverage Type (338-5C) required on Reversals to downstream Payers',
    U7: 'Missing or Invalid Pharmacy Service Type',
    VA: 'Pay to Qualifier Value not Supported',
    VB: 'Generic Equivalent Product ID Qualifier Value not Supported',
    VC: 'Pharmacy Service Type Value not Supported',
    VD: 'Eligibility Search Time Frame Exceeded',
    VE: 'Missing or Invalid Diagnosis Code Count',
    WE: 'Missing or Invalid Diagnosis Code Qualifier',
    W9: 'Accumulated Gross Covered Drug Cost Amount must be equal to or greater than Zero',
    XE: 'Missing or Invalid Clinical Information Counter',
    XZ: 'Missing or Invalid Associated Prescription/Service Reference Number Qualifier',
    X1: 'Accumulated Patient True out of Pocket exceeds Maximum',
    X2: 'Accumulated Gross Covered Drug Cost exceeds Maximum',
    X3: 'Out of order Accumulator Months',
    X4: 'Accumulator Year not current or prior year',
    X5: 'Missing or Invalid Financial Information Reporting Request Insurance Segment',
    X6: 'Missing or Invalid Request Financial Segment',
    X7: 'Financial Information Reporting Request Insurance Segment Required for Financial',
    X8: 'Procedure Modifier Code Count exceeds Number of Occurrences Supported',
    X9: 'Diagnosis Code Count exceeds Number of Occurrences Supported',
    X0: 'Missing or Invalid Associated Prescription/Service Fill Number',
    YA: 'Compound Ingredient Modifier Code Count exceeds Number of Occurrences',
    YB: 'Other Amount Claimed Submitted Count exceeds Number of Occurrences Supported',
    YC: 'Other Payer Reject Count exceeds Number of Occurrences Supported',
    YD: 'Other Payer-Patient Responsibility Amount Count exceeds Number of Occurrences Supported',
    YE: 'Submission Clarification Code Count exceeds Number of Occurrences Supported',
    YF: 'Question Number/Letter Count exceeds Number of Occurrences Supported',
    YG: 'Benefit Stage Count Exceeds Number of Occurrences Supported',
    YH: 'Clinical Information Counter exceeds Number of Occurrences Supported',
    YJ: 'Medicaid Agency Number not Supported',
    YK: 'Missing or Invalid Service Provider Name',
    YM: 'Missing or Invalid ervice Provider Street Address',
    YN: 'Missing or Invalid Service Provider City Address',
    YP: 'Missing or Invalid Service Provider State/Province Code Address',
    YQ: 'Missing or Invalid Service Provider Zip/Postal Code',
    YR: 'Missing or Invalid Patient ID Associated State/Province Address',
    YS: 'Missing or Invalid Purchaser Relationship Code',
    YT: 'Missing or Invalid Seller Initials',
    YU: 'Missing or Invalid Purchaser ID Qualifier',
    YV: 'Missing or Invalid Purchaser ID',
    YW: 'Missing or Invalid Purchaser ID Associated State/Province Code',
    YX: 'Missing or Invalid Purchaser Date of Birth',
    YY: 'Missing or Invalid Purchaser Gender Code',
    YZ: 'Missing or Invalid Purchaser First Name',
    Y0: 'Missing or Invalid Purchaser Last Name',
    Y1: 'Missing or Invalid Purchaser Street Address',
    Y2: 'Missing or Invalid Purchaser City Address',
    Y3: 'Missing or Invalid Purchaser State/Province Code',
    Y4: 'Missing or Invalid Purchaser Zip/Postal Code',
    Y5: 'Missing or Invalid Purchaser Country Code',
    Y6: 'Missing or Invalid Time of Service',
    Y7: 'Missing or Invalid Associated Prescription/Service Provider ID Qualifier',
    Y8: 'Missing or Invalid Associated Prescription/Service Provider ID',
    Y9: 'Missing or Invalid Seller ID',
    Z0: 'Purchaser Country Code Value not Supported for Processor/Payer',
    Z1: 'Prescriber Alternate ID Qualifier Value not Supported',
    Z2: 'Missing or Invalid Purchaser Segment',
    Z3: 'Purchaser Segment Present on a Non-Controlled Substance Reporting Transaction',
    Z4: 'Purchaser Segment Required on a Controlled Substance Reporting Transaction',
    Z5: 'Missing or Invalid Service Provider Segment',
    Z6: 'Service Provider Segment Present on a Non-Controlled Substance Reporting',
    Z7: 'Service Provider Segment Required on a Controlled Substance Reporting Transaction',
    Z8: 'Purchaser Relationship Code Value not Supported',
    Z9: 'Prescriber Alternate ID not Covered',
    ZA: 'The Coordination of Benefits/Other Payments Segment is Mandatory to a Downstream Payer',
    ZB: 'Missing or Invalid Seller ID Qualifier',
    ZC: 'Associated Prescription/Service Provider ID Qualifier Value not Supported forProcessor/Payer',
    ZD: 'Associated Prescription/Service Reference Number Qualifier Value not Supported',
    ZE: 'Missing or Invalid Measurement Date',
    ZF: 'Missing or Invalid Sales Transaction ID',
    ZK: 'Missing or Invalid Prescriber ID Associated State/Province Address',
    ZM: 'Missing or Invalid Prescriber Alternate ID Qualifier',
    ZN: 'Purchaser ID Qualifier Value not Supported for Processor/Payer',
    ZP: 'Missing or Invalid Prescriber Alternate ID',
    ZQ: 'Missing or Invalid Prescriber Alternate ID Associated State/Province Address',
    ZS: 'Missing or Invalid Reported Payment Type',
    ZT: 'Missing or Invalid Released Date',
    ZU: 'Missing or Invalid Released Time',
    ZV: 'Reported Payment Type Value not Supported',
    ZW: 'Missing or Invalid Compound Preparation Time',
    ZX: 'Missing or Invalid CMS Part D Contract ID',
    ZY: 'Missing or Invalid Medicare Part D Plan Benefit Package (PBP)',
    ZZ: 'Cardholder ID submitted is Inactive. New Cardholder ID on File.'
};
